import React, { useState, useEffect } from 'react'

export const ScrollIndicator = () => {
    const [completion, setCompletion] = useState(0)
    useEffect(() => {
        const updateComplete = () => {
            const scrollProgress = window.scrollY
            const scrollHeight = document.body.scrollHeight - window.innerHeight
            if (scrollHeight) {
                setCompletion(Number(scrollProgress / scrollHeight).toFixed(2) * 100)
            }
        }
        window.addEventListener('scroll', updateComplete)
    }, [])
    return completion
}
