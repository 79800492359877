// import React, { useRef, useState } from 'react'
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react'

// // Import Swiper styles
// // import 'swiper/css'
// import 'swiper/css/pagination'

// // import './style.css'
import TenTimes from './../../assets/Why Homepage images/1/10times_mobile.svg'
import PayTen from './../../assets/Why Homepage images/1/pay10_mobile.svg'
import StuckC from './../../assets/Why Homepage images/1/stuc_mobile.svg'

// // import required modules
// import { Pagination } from 'swiper'

// export function CarouselNew() {
// 	return (
// 		<>
// 			<Swiper
// 				slidesPerView={'auto'}
// 				spaceBetween={30}
// 				pagination={{
// 					clickable: true,
// 				}}
// 				modules={[Pagination]}
// 				className='mySwiper'
// 			>
// 				<SwiperSlide>
// 					<img src={TenTimes} alt='' className='' />{' '}
// 					{/* <p className='text-white text-carousel'> Event Landing Page</p> */}
// 					{/* <img src={PayTen} alt='' className='' />{' '} */}
// 				</SwiperSlide>{' '}
// 				<SwiperSlide>
// 					<img src={PayTen} alt='' className='' />{' '}
// 					{/* <p className='text-white text-carousel'> Android Application</p> */}
// 				</SwiperSlide>{' '}
// 				<SwiperSlide>
// 					<img src={StuckC} alt='' className='' />{' '}
// 					{/* <p className='text-white text-carousel2'> Website</p> */}
// 				</SwiperSlide>
// 			</Swiper>
// 		</>
// 	)
// }

import React, { useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

import './style.scss'

// import required modules
import { FreeMode, Pagination } from 'swiper'

export function CarouselNew() {
	return (
		<>
			<Swiper
				slidesPerView={1.5}
				spaceBetween={30}
				freeMode={true}
				modules={[FreeMode, Pagination]}
				className='mySwiper'
			>
				<SwiperSlide>
					<img src={TenTimes} alt='' className='image-1-carousel' />{' '}
					{/* <p className='text-white text-carousel'> Event Landing Page</p> */}
				</SwiperSlide>{' '}
				<SwiperSlide>
					<img src={PayTen} alt='' className='image-2-carousel' />{' '}
					{/* <p className='text-white text-carousel'> Android Application</p> */}
				</SwiperSlide>{' '}
				<SwiperSlide>
					<img src={StuckC} alt='' className='image-3-carousel' />{' '}
					{/* <p className='text-white text-carousel2'> Website</p> */}
				</SwiperSlide>
			</Swiper>
		</>
	)
}
