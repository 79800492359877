import React, { useEffect, useState } from 'react'
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material'
import Header from '../../components/HeaderWhite'
import PortFolioBall from "./../../assets/Portfolio/Blueball.png"
import Flower from "./../../assets/Portfolio/18.svg"
import BitFire from "./../../assets/Portfolio/bitfire.png"
import Uptrado from "./../../assets/Portfolio/Test .png"
import TenTimes from "./../../assets/Portfolio/10t.png"
import UX from "./../../assets/Portfolio/Vector.svg"
import WireFrame from "./../../assets/Portfolio/Vector (1).svg"
import Dev from "./../../assets/Portfolio/Vector (2).svg"
import Pitch from "./../../assets/Portfolio/Pitch.svg"
import "./portfolio.scss"
import FlowerFooter from './../../assets/Why Homepage images/flowerfooter.svg'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { config } from '../../config'
import axios from "axios"

export const PortFolio = () => {
    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [loader, setLoader] = useState(true)
    const [idea, setIdea] = useState("")


    //handle submit
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false
        ) {
            alert('Please enter a valid email address.')
            return
        }
        else if (email === "") {
            alert("Email cannot be empty!")
            return

        }

        else if (idea === "") {
            alert("Tell us about your Idea? ")
        }

        const Header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        await axios.post(`${config.formApi}`, { name: name, text: idea, email: email }, Header)
            .then(res => {
                const { success } = res.data
                if (success === false) {
                    return alert(res.data.message)
                } else {
                    alert('Form submited. ')
                }
            })
    }


    useEffect(() => {
        setLoader(false)
    }, [loader])

    return (

        <>

            {
                loader ? (
                    <div>
                        loader
                    </div>
                )
                    : (
                        <motion.div className='portfolio-container-main poppins-med bg-black'
                            initial={{ opacity: 0.5 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Header />
                            <div className='portfolio-cont-1 '>
                                <div className='first-fold homepage-heroimage-container flex justify-between'>
                                    <div className='portfolio-container'>
                                        <p className='portfolio-text homepage-hero-text text-white manrope-bold '>
                                            Portfolio
                                        </p>
                                    </div>
                                    <img src={PortFolioBall} alt="" className='absolute blueball-image' />
                                    <img src={Flower} alt="" className='absolute right-0 top--5 flower-image' />
                                </div>
                            </div>
                            <div className='bring-container flex  flex-col px-20'>
                                <p className='bringing-port portfolio-text text-white manrope mb-20 text-center' >
                                    Bringing ideas to life, digitally!
                                </p>

                                <div className='prods-by-why'>
                                    <div className='products-why flex mt-10 justify-evenly w-full pt-20'>
                                        <img src={BitFire} alt="" srcset="" className='prod-image' />
                                        <div className=' projects-why-name flex flex-col justify-center items-start gap-4 mt-10'>
                                            <div className='text-white manrope-bold text-4xl '>Bitfire</div>
                                            <div>
                                                <div className='project-details text-white text-lg'>
                                                    Type: Android Application
                                                </div>
                                                <div className='project-details text-white text-lg'>
                                                    Duration: 4 weeks
                                                </div>
                                            </div>
                                            <button className='portfolio-button'
                                                onClick={() => {
                                                    navigate("/bitfire-case-study")
                                                }}
                                            >View</button>
                                        </div>

                                    </div>
                                    <div className='research-why flex w-full justify-around text-lg px-20 my-20'>
                                        <div className='text-white flex items-center gap-2'>
                                            <img src={UX} alt="" />
                                            UX Research & Feature Recommendation
                                        </div>
                                        <div className='text-white flex items-center gap-2'>
                                            <img src={WireFrame} alt="" />
                                            Wireframes & Visuals </div>
                                        <div className='text-white flex items-center gap-2'>
                                            <img src={Dev} alt="" />
                                            Development</div>
                                    </div>
                                </div>

                                <div className='prods-by-why'>
                                    <div className='products-why flex mt-10 flex-row-reverse  justify-evenly w-full  pt-20'>
                                        <img src={Uptrado} alt="" srcset="" />
                                        <div className=' projects-why-name flex flex-col justify-center items-start gap-4 mt-10'>
                                            <div className='text-white manrope-bold text-4xl '>Uptrado</div>
                                            <div>
                                                <div className='project-details text-white text-lg'>
                                                    Type: Custom Dashboard
                                                </div>
                                                <div className='project-details text-white text-lg'>
                                                    Duration: 12 weeks
                                                </div>
                                            </div>
                                            <button className='portfolio-button'
                                                onClick={() => {
                                                    navigate("/uptrado")
                                                }}
                                            >View</button>
                                        </div>

                                    </div>
                                    <div className='research-why flex  w-full justify-around text-lg px-20 my-20 '>
                                        <div className='text-white flex items-center gap-2'>
                                            <img src={UX} alt="" />
                                            UX Research & Feature Recommendation
                                        </div>
                                        <div className='text-white flex items-center gap-2'>
                                            <img src={WireFrame} alt="" />
                                            Wireframes & Visuals </div>
                                        <div className='text-white flex items-center gap-2'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>

                                <div className='prods-by-why'></div>
                                <div className='products-why flex mt-10 justify-evenly w-full pt-20'>
                                    <img src={TenTimes} alt="" srcset="" />
                                    <div className=' projects-why-name flex flex-col justify-center items-start gap-4 mt-10'>
                                        <div className='text-white manrope-bold text-4xl '>10Times</div>
                                        <div>
                                            <div className='project-details text-white text-lg'>
                                                Type: Landing Page
                                            </div>
                                            <div className='project-details text-white text-lg'>
                                                Duration: 4 weeks
                                            </div>
                                        </div>
                                        <button className='portfolio-button'
                                            onClick={() => {
                                                navigate("/tentimes")
                                            }}
                                        >View</button>
                                    </div>

                                </div>
                                <div className='research-why flex w-full justify-around text-lg px-20 my-20'>
                                    <div className='text-white flex items-center gap-2'>
                                        <img src={UX} alt="" />
                                        <p>
                                            UX Research & Feature Recommendation
                                        </p>
                                    </div>
                                    <div className='text-white flex items-center gap-2'>
                                        <img src={WireFrame} alt="" />
                                        <p>
                                            Wireframes & Visuals
                                        </p>
                                    </div>
                                    <div className='text-white flex items-center gap-2'>
                                        <img src={Pitch} alt="" />
                                        <p>
                                            Pitch Deck
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className='footer-portfolio'>
                                <div className='fold-7 text-white'>
                                    <div className='manrope-bold lets-build'>
                                        {' '}
                                        Let's build something great together
                                    </div>
                                    <div className='get-in-touch-container '>
                                        <button className='ripple  get-in-touch homepage-button manrope'>
                                            Get in Touch
                                        </button>
                                    </div>

                                    <div className='form-container'>
                                        <img src={FlowerFooter} alt='' className='flower-image' />
                                        <div className='contact-form'>
                                            <form
                                                action='
						'
                                                onSubmit={handleSubmit}
                                            >
                                                <div className='input_container gap-5'>
                                                    <div className='flex flex-col my-2 gap-2'>
                                                        <label htmlFor=''>Full Name</label>
                                                        <input type='text' placeholder='' value={name} onChange={(e) => {
                                                            e.preventDefault()
                                                            setName(e.target.value)
                                                        }} />

                                                    </div>
                                                    <div className='flex flex-col my-2 gap-2'>
                                                        <label htmlFor=''>Email Id</label>
                                                        <input type='text' placeholder='' value={email} onChange={(e) => {
                                                            e.preventDefault()
                                                            setEmail(e.target.value)
                                                        }} />
                                                    </div>
                                                    <div className='flex flex-col my-2 gap-2'>
                                                        <label htmlFor=''>Tell Us About Your Idea</label>
                                                        <textarea type='text' placeholder=''
                                                            rows="8"
                                                            value={idea} onChange={(e) => {
                                                                e.preventDefault()
                                                                setIdea(e.target.value)

                                                            }}
                                                            className="form-text-area"
                                                        />

                                                    </div>
                                                    <button className='ripple form-button font-monorope'>
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className='lets-build-phone flex flex-col justify-center items-center text-white text-3xl text-center my-20 gap-10 px-10'>
                                    Let's build something great together
                                    <button className='homepage-button'>Get In Touch</button>
                                </div>
                                <div className='fold-8 text-white manrope'>
                                    <div className='footer-1 flex '>
                                        <p
                                            onClick={() => {
                                                navigate("/")
                                            }}
                                        >Home</p>
                                        <p
                                            onClick={() => {
                                                navigate("/about-us")
                                            }}
                                        >About </p>
                                        <p
                                            onClick={() => {
                                                navigate("/portfolio")
                                            }}
                                        >Portfolio</p>
                                        <p
                                            onClick={() => {
                                                navigate("/contact")
                                            }}
                                        >Contact Us</p>
                                    </div>

                                    <div className='footer-2 '>
                                        <div className='social text-white'>
                                            <a href="https://www.linkedin.com/company/why-ventures">

                                                <LinkedIn sx={{ fontSize: '40px' }} />

                                            </a>
                                            {/* <Facebook sx={{ fontSize: '40px' }} />
                                            <Twitter sx={{ fontSize: '40px' }} /> */}
                                        </div>
                                        <div className='social-2 text-white'>
                                            <a href="https://www.linkedin.com/company/why-ventures">

                                                <LinkedIn sx={{ color: '#555555', fontSize: '40px' }} />

                                            </a>
                                            {/* <Facebook sx={{ color: '#555555', fontSize: '40px' }} />
                                            <Twitter sx={{ color: '#555555', fontSize: '40px' }} /> */}
                                        </div>
                                        <div className='inq'>
                                            For Queries <br />
                                            <a className='font-bold' href="mailto:admin@whyventures.org"> admin@whyventures.org</a>
                                        </div>
                                        <div className='based'>Our bunker is in Delhi, India</div>
                                    </div>
                                </div>
                            </div>


                        </motion.div>
                    )
            }

        </>





    )

}
