import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import UptradoCaseStudyImage1 from './../../assets/Uptrado/Uptrado Homepage.svg'
import PinIcon from './../BitfireCaseStudy/../../assets/bitfire-case-study/PS_pin.svg'
import Arrow from './../../assets/Birfire images/arrow.svg'
import OurProcess1 from './../../assets/Uptrado/Discover.svg'
import OurProcess2 from './../../assets/Uptrado/Define.svg'
import OurProcess3 from './../../assets/Uptrado/Ideate.svg'
import OurProcess4 from './../../assets/Uptrado/Design.svg'
import Feature1 from './../../assets/Uptrado/Feature1.svg'
import Feature2 from './../../assets/Uptrado/Feature 2.svg'
import Feature3 from './../../assets/Uptrado/Feature 3.svg'
import Competitor1 from './../../assets/Uptrado/Ola Share.svg'
import Competitor2 from './../../assets/Uptrado/Bla Bla Car.svg'
import UserPersona from './../../assets/Uptrado/Group 1000004825.svg'
import TaskMapping from './../../assets/Uptrado/Task-Mapping.svg'
import Scene1 from './../../assets/Uptrado/BUYER_FLOW.svg'
import Scene2 from './../../assets/Uptrado/Supplier_Journey.svg'
import Sketch1 from './../../assets/Uptrado/Sletch-1.png'
import Sketch2 from './../../assets/Uptrado/Sletch-1.png'
import Sketch3 from './../../assets/Uptrado/Sletch-1.png'
import Sketch4 from './../../assets/Uptrado/Sletch-1.png'
import Sketch5 from './../../assets/Uptrado/Sletch-1.png'
import Sketch6 from './../../assets/Uptrado/Sletch-1.png'
import Sketch7 from './../../assets/Uptrado/Sletch-1.png'
import Visuals1 from './../../assets/Uptrado/Uptrado Login.svg'
import Visuals2 from './../../assets/Uptrado/quotations 1.svg'
import Visuals3 from './../../assets/Uptrado/Visual3.svg'
import Visuals4 from './../../assets/Uptrado/Visual4.svg'

const UptradoCaseStudy = () => {
	const [loader, setLoader] = useState(true)


	useEffect(() => {
		setLoader(false)
	}, [loader])
	return (
		<>
			{
				loader ? (
					<div>
						loader
					</div>
				)
					: (
						<div className='bitfire-case-study-container poppins-med'>
							<Header />
							<div className='bitfire-case-study-content-container container-sub'>
								<div className='case-study-buttons-container flex px-32 py-4 gap-x-2'>
									<button className='case-study-button '>UI Case Study</button>
									<button className='case-study-button '>UX Case Study</button>
								</div>
								<div className='case-study-text-content'>
									<h1 className='heading-1 poppins-med text-primary-color px-32 py-4 text-4xl font-semibold'>
										UX & UI of raw material procurement platform
									</h1>
									<p className='text-1 text-black-1 px-32 py-6 '>
										Uptrado connects traders, importers & suppliers in pharmaceutical
										sector and helps them evolve their supply chain, sales and resource
										management.
									</p>
									<p className='text-1 text-black-1 px-32 py-6 '>Duration: 4 months</p>
								</div>
								<div className='image-hero flex justify-center py-6 object-contain aspect-w-16 aspect-h-9 mb-20'>
									<img src={UptradoCaseStudyImage1} alt='' />
								</div>
								<div className='problem-statement-container px-48 py-16 mx-32 flex justify-center flex-col poppins text-primary-color rounded-lg relative mb-20'>
									<p className='problem-statement-heading text-4xl font-semibold py-2'>
										Problem Statement
									</p>
									<p className='problem-statement-text py-6'>
										{' '}
										The existing platform needed features to serve a large customer base
										and make the buying and selling process more intuitive.
									</p>
									<img src={PinIcon} alt='pin-icon' className='pin-icon absolute ' />
								</div>
								<div className='solution-container text-primary-color mx-32 mb-20'>
									<p className='solution-heading text-4xl font-semibold'>
										Our Solution
									</p>
									<p className='problem-statement-text py-6'>
										We offered solutions for various features and modules like Exchange,
										Landing Pages, and many more features & functionalities across web.
										We also provided creative design services like emailers, graphics,
										and pitch deck. <br /> <br /> Uptrado already had a product in place
										when they approached us. Since we were assigned various modules to
										be worked on, our solution was aimed at ensuring a unifrom design
										system, define the design guidelines, the navigation models and
										delivers a great user experience.
									</p>
								</div>
								<div className='objective-container text-primary-color mx-32 mt-20 '>
									<p className='objective-heading text-4xl font-semibold'>
										Objectives & Goals
									</p>
									<ul className='flex py-6 gap-x-20'>
										<li>To automate the raw material supply chain end-to-end.</li>
										<li>
											To gather the largest pool of raw material suppliers &
											manufacturers in pharmaceutical industry.
										</li>
									</ul>
								</div>
								<div className='process-container text-primary-color mx-32 mt-20 '>
									<p className='process-heading text-4xl font-semibold'>Our Process</p>
									<div className='process-image-container flex justify-around py-10 object-contain aspect-w-16 aspect-h-9 mb-20 items-start'>
										<img
											src={OurProcess1}
											alt='process'
											className='our-process-image m-10'
										/>
										<img
											src={OurProcess2}
											alt='process'
											className='our-process-image m-10'
										/>
										<img
											src={OurProcess3}
											alt='process'
											className='our-process-image m-10'
										/>
										<img
											src={OurProcess4}
											alt='process'
											className='our-process-image m-10'
										/>
										{/* <img src={BitfireCaseStudyImage1} alt='' /> */}
									</div>
								</div>
								<div className='business-container text-primary-color mx-32 mt-20 '>
									<p className='business-heading text-4xl font-semibold'>
										Business Challenges
									</p>
									<div>
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Users would have difficulty in adapting to the technology who were used to primitive methods of buying and selling over calls.

										</p>{' '}
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Security of seller’s quotations.

										</p>{' '}
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Verification of sellers and manufacturers joining the network
										</p>{' '}
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Communicate multiple prices from different sellers to manufacturers.

										</p>
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Keeping Jacob’s law in sight, the biggest challenge was to keep the digital process as similar to tradition process of buying and selling products over call, so the user is able to intuitively navigate the dashboard.

										</p>
									</div>
								</div>{' '}

								<div className='quantitative-container text-primary-color mx-32 mt-20 '>
									<p className='quantitative-heading text-4xl font-semibold'>
										Product Users
									</p>
									<p className='gap-x-2 mt-10 mb-20'>
										Our target audience is the population of
										<span className='text-yellow mx-1'>

											buyers or purchase managers aging 30-40.
										</span>
									</p>{' '}
								</div>

								<div className='quantitative-container text-primary-color mx-32 mt-20 '>
									<p className='quantitative-heading text-4xl font-semibold'>
										Quantitative Research
									</p>
									<p className='observation-heading heading-main text-2xl font-semibold'>
										Observations
									</p>
									<div className='flex justify-center align-center flex-column '>
										<div className='observation-content flex flex-col flex mb-10 '>
											<div className='observation-info flex justify-between mx-50 my-10'>
												<div className='m-10 content-center'>
													<p className='text-5xl text-heading-yellow font-bold text-yellow'>
														30 hrs
													</p>
													<p className='w-200 p-width'>
														Manufacturers wasted more than 30 hours every week negotiating and closing raw material deals.
													</p>
												</div>
												<div className='m-10 content-center'>
													<p className='text-5xl text-heading-yellow font-bold text-yellow'>
														80%
													</p>
													<p className='w-200 p-width'>
														of the deals used to happen over calls and emails.
													</p>
												</div>
											</div>
											<div className='observation-info  flex justify-between mx-50 my-10'>
												<div className='m-10 content-center '>
													<p className='text-5xl text-heading-yellow font-bold text-yellow'>
														20%
													</p>
													<p className='w-200 p-width'>
														chance of losing out on important bids.
													</p>
												</div>
												<div className='m-10 content-center '>
													<p className='text-5xl text-heading-yellow font-bold text-yellow'>
														60%
													</p>
													<p className='w-200 p-width'>
														sellers were unavilable to the manufacturers because they had no common place to connect or contact them.
													</p>
												</div>

											</div>
											<div className='m-10 content-center '>
												<p className='text-5xl text-heading-yellow font-bold text-yellow'>
													5%
												</p>
												<p className='w-200 p-width'>
													possibility of receiving wrong shipment of materials leading to monetary and time loss.
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className='userneeds-container container-sub text-primary-color mx-32 '>
									<p className='userneeds-heading heading-main text-4xl font-semibold'>
										User Needs
									</p>
									<div>
										<p className='flex user-need-text gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											To connect with a large pool of suppliers .and compare best prices.
										</p>{' '}
										<p className='flex user-need-text gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											To receive multiple quotes to buy a product on single platform.
										</p>{' '}
										<p className='flex user-need-text gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											To compare, negotiate and close the quotations from anywhere on any device.
										</p>{' '}
									</div>
								</div>{' '}
								<div className='features-container container-sub text-primary-color mx-32 mt-20 '>
									<p className='userneeds-heading heading-main text-4xl font-semibold'>
										Features & Functionalities
									</p>
									<div className='feature-image-container flex justify-around py-10 object-contain aspect-w-16 aspect-h-9 items-start'>
										<img src={Feature1} alt='process' className='feature-image m-10' />
										<img src={Feature2} alt='process' className='feature-image m-10' />
										<img src={Feature3} alt='process' className='feature-image m-10' />
										{/* <img src={BitfireCaseStudyImage1} alt='' /> */}
									</div>
								</div>{' '}
								<div className='product-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
									<p className='userneeds-heading heading-main text-4xl font-semibold'>
										Product User Challenges
									</p>
									<div>
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Most purchase managers felt annoyed by constant calls and didn’t prefer emails.
										</p>{' '}
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											On emails and calls, buyers waste a lot of time calling to & fro, comparing, negotiating prices by traditional methods.
										</p>{' '}
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											It’s difficult for buyers to find new suppliers on urgent basis if the existing one is unable to fulfill the order requirements.
										</p>{' '}
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Both suppliers and buyers find it time consuming to track expenditure, savings, order details, etc in a book with the possibility of errors.
										</p>{' '}
									</div>
								</div>{' '}
								<div className='competitor-container-1 container-sub text-primary-color mx-32 flex justify-between items-center'>
									<div>
										<p className='userneeds-heading heading-main text-4xl font-semibold'>
											Competitor Analysis
										</p>
										<div>
											<p className='competitor-sub-heading  heading-main text-2xl font-semibold mt-5'>
												Competitor 1
											</p>
											<p className='flex gap-x-20 mt-10 mb-20'>
												SAP Ariba
											</p>{' '}
										</div>
										<div>
											<p className='userneeds-heading heading-main text-2xl font-semibold mt-5'>
												Features:
											</p>
											<p className='flex gap-x-20 mt-10'>
												<img src={Arrow} alt='' />
												e-procurement and supply chain cloud solutions
											</p>{' '}
											<p className='flex gap-x-20 mt-10'>
												<img src={Arrow} alt='' />
												detailed view on entire sourcing, contracting, and spend analysis processes
											</p>{' '}
										</div>
									</div>
									<div className='red-img'>
										<img src={Competitor1} alt='' srcset='' />
									</div>
								</div>{' '}
							</div>
							<div className='competitor-container-1 container-sub text-primary-color mx-32 flex justify-between items-center my-10'>
								<div className='red-img'>
									<img src={Competitor2} alt='' srcset='' />
								</div>
								<div>
									<div>
										<p className='competitor-sub-heading  heading-main text-2xl font-semibold mt-5'>
											Competitor 2
										</p>
										<p className='flex gap-x-20 mt-10 mb-20'>
											Tradeshift
										</p>{' '}
									</div>
									<div>
										<p className='userneeds-heading heading-main text-2xl font-semibold mt-5'>
											Features:
										</p>
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Remove paper and manual processes across procure-to-pay
										</p>{' '}
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											Focus on seller relationship management
										</p>{' '}
									</div>
								</div>
							</div>{' '}
							<div className='user-persona-container container-sub text-primary-color mx-32 mt-20 '>
								<p className='objective-heading heading-main text-4xl font-semibold'>
									User Persona
								</p>
								<div className='flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 mb-1y0'>
									<img src={UserPersona} alt='process' />
								</div>
							</div>
							<div className='design-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
								<p className='design-heading heading-main text-4xl font-semibold'>
									Task Mapping
								</p>
								<div>
									<p className='flex design-text-p gap-x-20 mt-10'>

										Raising a product requirement for single or multiple products
									</p>{' '}

									<div className='flex design-text-p justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
										<img src={TaskMapping} alt='process' />
									</div>
								</div>
							</div>{' '}
							<div className='product-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
								<p className='userneeds-heading heading-main text-4xl font-semibold'>
									Task Flow
								</p>
								<div>
									<p className='competitor-sub-heading  heading-main text-2xl font-semibold mt-5 py-5'>
										Scenario 1 :
									</p>
									<p className='flex gap-x-20 mt-10'>

										Manufacturer wants to post a requirement for maize starch as one of the ingredients for a certain medication.
									</p>{' '}
									<div className='flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
										<img src={Scene1} alt='process' />
									</div>
								</div>
								<p className='competitor-sub-heading  heading-main text-2xl font-semibold mt-5 py-5'>
									Scenario 2 :
								</p>
								<p className='flex gap-x-20 mt-10'>A supplier received the request, has the required quantity of maize starch, wants to negotiate for better price and finally close the quote.</p>
								<div>
									<div className='flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
										<img src={Scene2} alt='process' />
									</div>
								</div>
							</div>{' '}
							<div className='architecture-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
								<p className='userneeds-heading heading-main text-4xl font-semibold mb-10'>
									Sketches
								</p>
								<div className='wireframe-images flex  justify-center gap-x-10 py-10 mt-20'>
									{' '}
									<div>

										<img src={Sketch1} alt='' />
										<img src={Sketch2} alt='' />
										<img src={Sketch3} alt='' />
										<img src={Sketch4} alt='' />
									</div>
									<div>
										<img src={Sketch5} alt='' />
										<img src={Sketch6} alt='' />
										<img src={Sketch7} alt='' />
									</div>

								</div>
							</div>{' '}
							<div className='visual-container container-sub flex flex-col text-primary-color mx-32 mt-20 mb-20 '>
								<p className='visual-heading heading-main text-4xl font-semibold mb-20'>
									Major Screens
								</p>
								<div className='-middle container-sub  flex justify-around mb-10'>
									<div className='visual-content-sub'>
										Login Screen
										<img src={Visuals1} alt='process' />
									</div>
								</div>
								<div className='-middle container-sub  flex justify-around mb-10'>
									<div className='visual-content-sub'>
										Buyer negotiation screen
										<img src={Visuals2} alt='process' />
									</div>
								</div>
								<div className=' container-sub  flex justify-around'>
									<div>
										Purchase order receipt
										<img src={Visuals3} alt='process' />
									</div>
								</div>
								<div className=' container-sub  flex justify-around'>
									<div>
										<img src={Visuals4} alt='process' />
									</div>
								</div>
							</div>
							<div className='learning-container container-sub p-10'>
								<div className='learning-container container-sub text-primary-color mx-32 mt-20 '>
									<p className='objective-heading heading-main text-4xl font-semibold'>
										My Learnings:
									</p>
									<div className='flex justify-center py-10 mb-20'>
										<p className='flex gap-x-20 mt-10 justify-center text-primary'>
											One of the major take-aways from the project were that this project were that the 360 degree design impementation that we did for the client which gave an understanding how to build a design systems that extends beyond digital web & dashboard design into email communication, PRD reports etc.
											<br />
											<br />
											This project was one-of-its kind in the sense, understanding the real-life persona, attend the actual client calls of the potential or exisiting uptrado platform users to understand the buying & selling process, validation of visuals concepts after every iteration from real suppliers and sellers by demonstrating an actual demo, taking a consensus on features and evolving the design accordingly.

										</p>{' '}
									</div>
								</div>
							</div>
							<div className='py-10 thanks mt-20 flex flex-col justify-center align-center'>
								<p className='objective-heading heading-main text-4xl font-semibold'>
									Thanks for reading
								</p>
								<p className=' px-10 mt-10 text-primary'>
									Do you have any project you would like us to bring to life? Send us a
									mail on &nbsp
									<a className='font-bold' href="mailto:admin@whyventures.org"> admin@whyventures.org</a>
								</p>{' '}
							</div>
						</div>
					)
			}
		</>

	)
}
export default UptradoCaseStudy
