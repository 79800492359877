import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import './bitfire-case-study.scss'
import BitfireCaseStudyImage1 from './../../assets/bitfire-case-study/bitfire-hero.png'
import PinIcon from './../BitfireCaseStudy/../../assets/bitfire-case-study/PS_pin.svg'
import Doge from './../../assets/Birfire images/elonbaba.png'
import Arrow from './../../assets/Birfire images/arrow.svg'
import RedBox from './../../assets/Birfire images/CA1.svg'
import CoinMarket from './../../assets/Birfire images/CA2.svg'
import UserPersona from './../../assets/Birfire images/Userpersona.svg'
import DesignInput from './../../assets/Birfire images/DI1.svg'
import StyleGuide from './../../assets/Birfire images/SG.svg'
import OurProcess1 from './../../assets/bitfire-case-study/Discover.svg'
import OurProcess2 from './../../assets/bitfire-case-study/Define.svg'
import OurProcess3 from './../../assets/bitfire-case-study/Ideate.svg'
import OurProcess4 from './../../assets/bitfire-case-study/Design.svg'
import Feature1 from './../../assets/bitfire-case-study/price alert.svg'
import Feature2 from './../../assets/bitfire-case-study/beastmode.svg'
import Feature3 from './../../assets/bitfire-case-study/analysis.svg'
import Architecture from './../../assets/Birfire images/architecture-min.png'
import BitFire1 from './../../assets/Birfire images/Sktch1.svg'
import BitFire2 from './../../assets/Birfire images/Sktch2.svg'
import BitFire3 from './../../assets/Birfire images/sktch3.svg'
import BitFire4 from './../../assets/Birfire images/sktch4.svg'
import BitFire5 from './../../assets/Birfire images/sktch5.svg'
import BitFire6 from './../../assets/Birfire images/sktch6.svg'
import Visuals1 from './../../assets/Birfire images/v1.svg'
import Visuals2 from './../../assets/Birfire images/v2.svg'
import Visuals3 from './../../assets/Birfire images/v3.svg'
// import { ScrollRestoration } from "react-router-dom"

// window.scrollTo(0, 0)



const BitfireCaseStudy = () => {

	const [loader, setLoader] = useState(true)


	useEffect(() => {
		setLoader(false)
	}, [loader])
	return (
		<>
			{
				loader ? (<div>
					loader...
				</div>) : (
					<div className='bitfire-case-study-container poppins-med' autoFocus={true}>
						{/* <ScrollRestoration /> */}
						<Header />
						<div className='bitfire-case-study-content-container container-sub'>
							<div className='case-study-buttons-container flex px-32 py-4 gap-x-2'>
								<button className='case-study-button '>UI Case Study</button>
								<button className='case-study-button '>UX Case Study</button>
							</div>
							<div className='case-study-text-content'>
								<h1 className='heading-1 poppins-med text-primary-color px-32 py-4 text-4xl font-semibold'>
									UX & UI of cryptocurrency analysis platform
								</h1>
								<p className='text-1 text-black-1 px-32 py-6 '>
									There are lots of applications that allow purchasing and selling
									cryptocurreny and others that give information on them, but hardly
									any that gives detailed analysis on the cryptomarket.
								</p>
							</div>
							<div className='image-hero flex justify-center py-6 object-contain aspect-w-16 aspect-h-9 mb-20'>
								<img src={BitfireCaseStudyImage1} alt='' />
							</div>
							<div className='problem-statement-container px-48 py-16 mx-32 flex justify-center flex-col poppins text-primary-color rounded-lg relative mb-20'>
								<p className='problem-statement-heading text-4xl font-semibold py-2'>
									Problem Statement
								</p>
								<p className='problem-statement-text py-6'>
									{' '}
									There was no application that allowed users in discovery phase to
									make data-informed decisions about cryptocurrency buying and selling
								</p>
								<img src={PinIcon} alt='pin-icon' className='pin-icon absolute ' />
							</div>
							<div className='solution-container text-primary-color mx-32 mb-20'>
								<p className='solution-heading text-4xl font-semibold'>
									Our Solution
								</p>
								<p className='problem-statement-text py-6'>
									We believe that by creating a unique educational application for
									cryptocurrency and analysis for crypto lovers, newbies or not, we
									will open up access to this world of digital currency to many people
									who were previously reluctant, build their loyalty and make
									cryptocurrency part of their daily life and saving habits.
								</p>
							</div>
							<div className='marketing-container text-primary-color mx-32 mb-20'>
								<p className='marketing-heading text-4xl font-semibold'>
									Why is market analysis important?
								</p>
								<p className='marketing-text py-6 mb-10'>
									I remember in the year 2017, the moment I entered my office, all
									everyone could talk about was bitcoin and how it’s price rose
									overnight and how they were all downloading various platforms to buy
									as many parts of bitcoin as they could. I started wondering why were
									my colleagues going gaga over something that they did not even care
									about a month ago? That’s where market sentiment analysis comes in
									picture. Rather than being calculative about the purchase, people
									started dealing in bitcoin because of FOMO. Which in turn spiked the
									value of bitcoin even more. <br /> <br />
									Let’s take Dogecoin as an example. A lot of Dogecoin's demand in its
									bull run likely came from social media hype (which led to positive
									market sentiment). Many traders and investors bought Dogecoin
									without considering the project's tokenomics or goals, but only
									because of the current market sentiment. Even a single tweet from a
									figure like Elon Musk is enough sometimes to cause positive or
									negative market sentiment.
								</p>
								<img src={Doge} alt='elon-doge' className='aspect-w-16 aspect-h-9' />
							</div>
							<div className='objective-container text-primary-color mx-32 mt-20 '>
								<p className='objective-heading text-4xl font-semibold'>
									Objectives & Goals
								</p>
								<ul className='flex py-6 gap-x-20'>
									<li>
										To give in-depth analysis to users on their selected
										cryptocurrencies
									</li>
									<li>
										To allow users to visualize their portfolio & profits and losses
										on investments
									</li>
								</ul>
							</div>
							<div className='process-container text-primary-color mx-32  '>
								<p className='process-heading text-4xl font-semibold'>Our Process</p>
								<div className='process-image-container flex justify-around py-10 object-contain aspect-w-16 aspect-h-9 mb-20'>
									<img
										src={OurProcess1}
										alt='process'
										className='our-process-image m-10'
									/>
									<img
										src={OurProcess2}
										alt='process'
										className='our-process-image m-10'
									/>
									<img
										src={OurProcess3}
										alt='process'
										className='our-process-image m-10'
									/>
									<img
										src={OurProcess4}
										alt='process'
										className='our-process-image m-10'
									/>
									{/* <img src={BitfireCaseStudyImage1} alt='' /> */}
								</div>
							</div>
							<div className='business-container text-primary-color mx-32 mt-20 '>
								<p className='business-heading text-4xl font-semibold'>
									Business Challenges
								</p>
								<div>
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Money if sent to the wrong wallet address by accident, the sender
										will be unable to reclaim the currency.
									</p>{' '}
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Highly Volatile Market
									</p>{' '}
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Chances of illegal activities
									</p>{' '}
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Country’s current cryptocurrency regulations keep fluctuating
									</p>
								</div>
							</div>{' '}
							'
							<div className='quantitative-container text-primary-color mx-32 mt-20 '>
								<p className='quantitative-heading text-4xl font-semibold'>
									Quantitative Research
								</p>
								<p className='flex gap-x-20 mt-10 mb-20'>
									We did a poll survey over various social media platforms like
									instagram and live Q&A session where 67 individuals participated.
									The questions revolved around why, how, when of their cryptocurrency
									trades
								</p>{' '}
								<p className='observation-heading heading-main text-2xl font-semibold'>
									Observations
								</p>
								<div className='flex justify-center align-center flex-column'>
									<div className='observation-content flex flex-col flex mb-10 '>
										<div className='observation-info flex justify-between mx-50 my-10'>
											<div className='m-10 content-center'>
												<p className='text-5xl text-heading-yellow font-bold text-yellow'>
													1 in 10
												</p>
												<p className='w-200 p-width'>
													people have started investing in cryptocurrency in the hopes
													of high returns.
												</p>
											</div>
											<div className='m-10 content-center'>
												<p className='text-5xl text-heading-yellow font-bold text-yellow'>
													33-34%
												</p>
												<p className='w-200 p-width'>
													of the individuals started trading due to ease of trade
													(trading apps)
												</p>
											</div>
										</div>
										<div className='observation-info  flex justify-between mx-50 my-10'>
											<div className='m-10 content-center '>
												<p className='text-5xl text-heading-yellow font-bold text-yellow'>
													43%
												</p>
												<p className='w-200 p-width'>
													traders deal in crypto on monthy to weekly basis rather than
													long-term holdings
												</p>
											</div>
											<div className='m-10 content-center '>
												<p className='text-5xl text-heading-yellow font-bold text-yellow'>
													6000
												</p>
												<p className='w-200 p-width'>
													different types of digital currencies but the top 10 make up
													for 88% of the total market share & Bitcoin being the most
													popular
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='userneeds-container container-sub text-primary-color mx-32 '>
								<p className='userneeds-heading heading-main text-4xl font-semibold'>
									User Needs
								</p>
								<div>
									<p className='flex user-need-text gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Users need technical analysis in order to trade in crypto and not
										just gamble or guess the rise and fall.
									</p>{' '}
									<p className='flex user-need-text gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Reliable data on previous patterns to predict the future trends of
										multiple cryptocurrencies
									</p>{' '}
									<p className='flex user-need-text gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Knowledge about buying as well as storage of these digital assets.
									</p>{' '}
								</div>
							</div>{' '}
							<div className='features-container container-sub text-primary-color mx-32 mt-20 '>
								<p className='userneeds-heading heading-main text-4xl font-semibold'>
									Features & Functionalities
								</p>
								<div className='feature-image-container flex justify-around py-10 object-contain aspect-w-16 aspect-h-9 '>
									<img src={Feature1} alt='process' className='feature-image m-10' />
									<img src={Feature2} alt='process' className='feature-image m-10' />
									<img src={Feature3} alt='process' className='feature-image m-10' />
									{/* <img src={BitfireCaseStudyImage1} alt='' /> */}
								</div>
							</div>{' '}
							<div className='product-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
								<p className='userneeds-heading heading-main text-4xl font-semibold'>
									Product User Challenges
								</p>
								<div>
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Most new comers felt anxious how safe cryptocurrency is.
									</p>{' '}
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Factors that affect the rise and fall of these cryptocurrencies.
										Legitimate and reliable news around these topics.
									</p>{' '}
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										Most people buy into the crypto fad, with no idea on how to make
										decisions and where to gather key insights regarding their
										portfolio.
									</p>{' '}
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										There was no app that allowed them to set alerts against prices,
										measure the profitability of their portfolio and quicky navigate
										throught 100’s of crytocurrencies while viewing their details.
									</p>{' '}
								</div>
							</div>{' '}
							<div className='competitor-container-1 container-sub text-primary-color mx-32 flex justify-between items-center'>
								<div>
									<p className='userneeds-heading heading-main text-4xl font-semibold'>
										Competitor Analysis
									</p>
									<div>
										<p className='competitor-sub-heading  heading-main text-2xl font-semibold mt-5'>
											Competitor 1
										</p>
										<p className='flex gap-x-20 mt-10 mb-20'>
											Cryptomood - Sentiment analysis tools for crypto trading
											decisions
										</p>{' '}
									</div>
									<div>
										<p className='userneeds-heading heading-main text-2xl font-semibold mt-5'>
											Features:
										</p>
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											AI-based predictions of market sentiments related to
											cryptocurrencies.
										</p>{' '}
										<p className='flex gap-x-20 mt-10'>
											<img src={Arrow} alt='' />
											an app-based platform that is available for iOS and Android
											platforms.
										</p>{' '}
									</div>
								</div>
								<div className='red-img'>
									<img src={RedBox} alt='' srcset='' />
								</div>
							</div>{' '}
						</div>
						<div className='competitor-container-1 container-sub text-primary-color mx-32 flex justify-between items-center my-10'>
							<div className='red-img'>
								<img src={CoinMarket} alt='' srcset='' />
							</div>
							<div>
								<div>
									<p className='competitor-sub-heading  heading-main text-2xl font-semibold mt-5'>
										Competitor 2
									</p>
									<p className='flex gap-x-20 mt-10 mb-20'>
										Coin marketcap - price-tracking website for cryptoassets
									</p>{' '}
								</div>
								<div>
									<p className='userneeds-heading heading-main text-2xl font-semibold mt-5'>
										Features:
									</p>
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										User friendly, easy-to-navigate UI and well thought UX
									</p>{' '}
									<p className='flex gap-x-20 mt-10'>
										<img src={Arrow} alt='' />
										90+ fiat currencies such as USD, EUR, JPY, KRW and CNY & 10
										different languages in app settings.
									</p>{' '}
								</div>
							</div>
						</div>{' '}
						<div className='user-persona-container container-sub text-primary-color mx-32 mt-20 '>
							<p className='objective-heading heading-main text-4xl font-semibold'>
								User Persona
							</p>
							<div className='flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 mb-1y0'>
								<img src={UserPersona} alt='process' />
							</div>
						</div>
						<div className='design-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
							<p className='design-heading heading-main text-4xl font-semibold'>
								Design Input
							</p>
							<div>
								<p className='flex design-text-p gap-x-20 mt-10'>
									<img src={Arrow} alt='' />
									Since the data to be displayed would be critical for user’s decision
									making, we chose a clean sans font Avenir
								</p>{' '}
								<p className='flex design-text-p gap-x-20 mt-10'>
									<img src={Arrow} alt='' />
									Dark background as it is less heavy on the eyes over a longer range
									of time. We tried experimenting with black and blue hues.
								</p>{' '}
								<p className='flex design-text-p gap-x-20 mt-10'>
									<img src={Arrow} alt='' />
									Qualitative survey on the shades of red and green to depict the
									highs and lows in the cryptocurrency prices.
								</p>{' '}
								<div className='flex design-text-p justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
									<img src={DesignInput} alt='process' />
								</div>
							</div>
						</div>{' '}
						<div className='product-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
							<p className='userneeds-heading heading-main text-4xl font-semibold'>
								Style Guide
							</p>
							<div>
								<div className='flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
									<img src={StyleGuide} alt='process' />
								</div>
							</div>
						</div>{' '}
						<div className='architecture-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
							<p className='architecture-heading heading-main text-4xl font-semibold'>
								Bitfire App architecture
							</p>
							<div className='architecture-image-container'>
								<div className=' flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
									<img
										className='architecture-image'
										src={Architecture}
										alt='process'
									/>
								</div>
							</div>
						</div>{' '}
						<div className='architecture-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
							<p className='userneeds-heading heading-main text-4xl font-semibold mb-10'>
								Sketches
							</p>
							<p className='flex gap-x-20 mt-10'>
								We created a wireframe to validate our hypothesis on the structure and
								layout of the product. The essence of the wireframe was for the
								flexibility in structure & visual iterations later on. The prototype
								was created using MARVEL.
							</p>{' '}
							<p className='wireframe-sub-heading heading-main text-2xl font-semibold mt-20'>
								Wireframe and Mock Up
							</p>
							<div className='wireframe-images flex justify-center gap-x-10 py-10 mt-20'>
								{' '}
								<img src={BitFire1} alt='' />
								<img src={BitFire2} alt='' />
								<img src={BitFire3} alt='' />
								<img src={BitFire4} alt='' />
								<img src={BitFire5} alt='' />
								<img src={BitFire6} alt='' />
							</div>
						</div>{' '}
						<div className='visual-container container-sub flex flex-col text-primary-color mx-32 mt-20 mb-20 '>
							<p className='visual-heading heading-main text-4xl font-semibold mb-20'>
								Visuals
							</p>
							<div className='visual-content-container container-sub  flex justify-around mb-10'>
								<div className='visual-content-sub'>
									<p className='visual-sub-heading heading-main text-2xl text-yellow font-semibold mt-20'>
										Welcome Screen
									</p>
									<p className='flex gap-x-20 mt-10 w-80 text-primary'>
										The splash or welcome screen is the entry point for users, it give
										the user a choice to navigate to register, enter their name &
										email, followed by password creation.
									</p>{' '}
								</div>
								<div className='visual-content-sub'>
									<img src={Visuals1} alt='process' />
								</div>
							</div>
							<div className='visual-content-container-middle container-sub  flex justify-around mb-10'>
								<div className='visual-content-sub'>
									<img src={Visuals2} alt='process' />
								</div>
								<div className='visual-content-sub'>
									<p className='visual-sub-heading heading-main text-2xl text-yellow font-semibold mt-20'>
										Authentication & Market Screens
									</p>
									<p className='flex gap-x-20 mt-10 w-80 text-primary'>
										Quick easy to navigate authentication screen to access the wallet
										interface. The user can select one currency at the start so he can
										follow the trend of that currency. Later on the user can keep
										adding currencies of interest to their portfolios.
									</p>{' '}
								</div>
							</div>
							<div className='visual-content-container container-sub  flex justify-around'>
								<div className='visual-content-sub'>
									<p className='visual-sub-heading heading-main text-2xl text-yellow font-semibold mt-20'>
										Beast Mode,Watchlist & Portfolio
									</p>
									<p></p>
									<p className='flex gap-x-20 mt-10 w-80 text-primary'>
										The Beast mode is the central place of the analysis and
										visualization mode. Here, users can view details of their chosen
										currencies, past trends, market sentiment & they can also search
										for other coins to compare highs and lows for particular time
										period e.g.: weekly, monthly, half yearly and so.
									</p>{' '}
								</div>
								<div>
									<img src={Visuals3} alt='process' />
								</div>
							</div>
						</div>
						<div className='learning-container container-sub p-10'>
							<div className='learning-container container-sub text-primary-color mx-32 mt-20 '>
								<p className='objective-heading heading-main text-4xl font-semibold'>
									My Learnings:
								</p>
								<div className='flex justify-center py-10 mb-20'>
									<p className='flex gap-x-20 mt-10 justify-center text-primary'>
										This was our first financial product, it gave us as understanding
										of how people traverse among stock market, crypto trading and what
										factors help in decision making. <br /> <br /> Considering the
										fast nature of intraday trading, building a product that allowed
										users to see key factors like news, details, feed, averages,
										graphs, of over 100 cryptocurrenies on a single screen without
										going through another screen that too on a mobile app itself was
										an exciting challenge. For this one page, we spent days getting it
										right, the colours, readability, architecture followed by
										rigourous testing to ensure a seamless and delightful experience.
									</p>{' '}
								</div>
							</div>
						</div>
						<div className='py-10 thanks mt-20 flex flex-col justify-center align-center'>
							<p className='objective-heading heading-main text-4xl font-semibold'>
								Thanks for reading
							</p>
							<p className=' px-10 mt-10 text-primary'>
								Do you have any project you would like us to bring to life? Send us a
								mail on &nbsp
								<a className='font-bold' href="mailto:admin@whyventures.org"> admin@whyventures.org</a>
							</p>{' '}
						</div>
					</div>
				)
			}
		</>

	)
}
export default BitfireCaseStudy
