import React from 'react'
import Header from '../../components/HeaderWhite'
import './homepage.scss'
import HeroImage from '../../assets/Why Homepage images/growing_plant-9430 2.svg'
import Waves from './../../assets/Why Homepage images/waves.svg'
import WhyA from './../../assets/Why Homepage images/questionmark.svg'
import Eliminates from './../../assets/Why Homepage images/gala_remove.png'
// import Eliminates from './../../assets/Why Homepage images/gala_remove.png'
import Helps from './../../assets/Why Homepage images/noun-bulb-1034389 1.svg'
import Road from './../../assets/Why Homepage images/healthicons_paved-road-alt-outline.svg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import BrainChain from './../../assets/Why Homepage images/logos/Brain Chain Logo 2.png'
import TenTimes from './../../assets/Why Homepage images/logos/10times-logo-HD 2.svg'
import Central from './../../assets/Why Homepage images/logos/Central_Square_Foundation_Logo 2.svg'
import Eq8 from './../../assets/Why Homepage images/logos/Eq8 logo 2.png'
import ItEx from './../../assets/Why Homepage images/logos/it-exchange-logo-1537773896 2.png'
import Pay10 from './../../assets/Why Homepage images/logos/Pay10_logo 2.svg'
import stuc from './../../assets/Why Homepage images/logos/StuC Logo Copy 2.svg'
import uptrado from './../../assets/Why Homepage images/logos/uptrado logo.svg'
import DotPattern from './../../assets/Why Homepage images/dot_pattern.svg'
import KloseDemo from './../../assets/Why Homepage images/klosedemo.png'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { useNavigate, } from 'react-router-dom'
import Flower from './../../assets/Why Homepage images/flowerfooter.svg'
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material'
import LogoMobile from './../../assets/Why Homepage images/brands-new.svg'
import ZigZag from './../../assets/Why Homepage images/phoneportfolioimg.svg'
import WhatDoWeDo from '../../assets/Why Homepage images/desktopporfolioimg.svg'
import { CarouselNew } from '../../components/carousel/carouselnew'
import { motion } from "framer-motion"
import { useState } from 'react'
import axios from "axios"
import { config } from '../../config'
// import { useNavigate } from 'react-router-dom'

export const HomePage = () => {
	const navigate = useNavigate()


	const ContactHandler = () => {
		window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
	}
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [idea, setIdea] = useState("")

	//handle submit

	//handle submit
	const handleSubmit = async (e) => {
		e.preventDefault()
		if (
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false
		) {
			alert('Please enter a valid email address.')
			return
		}
		else if (email === "") {
			alert("Email cannot be empty!")
			return

		}

		else if (idea === "") {
			alert("Tell us about your Idea? ")
		}

		const Header = {
			headers: {
				'Content-Type': 'application/json',
			}
		}

		await axios.post(`${config.formApi}`, { name: name, text: idea, email: email }, Header)
			.then(res => {
				const { success } = res.data
				if (success === false) {
					return alert(res.data.message)
				} else {
					alert('Form submited. ')
				}
			})
	}

	return (
		<motion.div className='homepage-container poppins-med bg-black'
			initial={{ opacity: 0.5 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.5 }}

		>
			<Header />
			<div className='fold-1-2 '>
				<div className='first-fold homepage-heroimage-container flex justify-between'>
					<div className='homepage-hero-text-container'>
						<p className='homepage-hero-text text-white manrope-bold '>
							Bringing your ideas to life...
						</p>
						<p className='homepage-hero-text-2 text-white my-10 text-lg manrope'>
							At Why, we work with founders like you and nurture your ideas
							bringing them to life digitally from 0 to 1.
						</p>
						<button className='ripple homepage-button z-10'
							onClick={ContactHandler}
						>
							Consult for free
						</button>
					</div>

					<img src={Waves} alt='' className='wave-image ' alt="wave" />
					<img src={HeroImage} className='homepage-hero-image' alt="wave2" />
				</div>

				<div className='first-fold-mobile homepage-heroimage-container'>
					<div className='homepage-hero-text-container'>
						<img src={HeroImage} className='homepage-hero-image-mobile' alt="wave3" />
						<p className='homepage-hero-text text-white manrope-bold '>
							Bringing your ideas to life...
						</p>
						<p className='homepage-hero-text-2 text-white my-10 text-lg manrope'>
							At Why, we work with founders like you and nurture your ideas
							bringing them to life digitally from 0 to 1.
						</p>
						<button className=' ripple  homepage-button z-10'
							onClick={() => {
								navigate("/contact")
							}}
						>
							Consult for free
						</button>
					</div>

					<img src={Waves} alt='' className='wave-image ' />
				</div>
				<div className='fold-2 asking-why-container relative flex justify-between'>
					<div className='asking-why-text-container text-container flex space-between'>
						<div className='asking-why-container-1'>
							<p className='text text-white manrope '>
								We believe, it’s not just about finding the right answers, its
								about asking the right questions!
							</p>

							<p
								className='asking-why-2 manrope text-white '
								style={{ fontSize: '68px', margin: '60px 0' }}
							>
								Asking <span style={{ color: '#A1D905' }}>Why</span>
							</p>
						</div>
						<div className='asking-why-container-2 flex justify-end'>
							<img src={WhyA} alt='' />
						</div>
					</div>

					<p
						className='asking-why manrope text-white absolute'
						style={{ fontSize: '64px' }}
					>
						Asking <span style={{ color: '#A1D905' }}>Why</span>
					</p>
					<div
						className='text-bottom-container text-white flex absolute justify-between '
						style={{ fontSize: '20px' }}
					>
						<p>
							{' '}
							<img className='bulb-chu-icon' src={Eliminates} alt='' />{' '}
							Eliminates assumptions
						</p>
						<p>
							{' '}
							<img className='icons-help' src={Helps} alt='' /> Helps understand
							the problem
						</p>
						<p>
							<img className='icons-help-2' src={Road} alt='' /> Defines a clear
							plan
						</p>
						<div className='about-us-btn-desktop'>
							<p
								className=' about-us-mobile text-green about-use-fold-2 ripple '
								style={{ fontSize: '20px', width: '200px' }}
								onClick={() => {
									navigate("/about-us")
								}}
							>
								About Us
								<ArrowForwardIcon />
							</p>
						</div>
						<div className='about-us-btn-mobile'>
							<button
								className='
							mobile-about-us-btn-2 font-bold
							'
								style={{ width: '200px' }}
								onClick={() => {
									navigate("/about-us")
								}}
							>
								About Us
								<ArrowForwardIcon />
							</button>
						</div>
					</div>
				</div>
				<div className='fold-3 my-40'>
					<div className='what-do-we-do-container my-10'>
						<div className='what-do-we-do-1'>
							<p className=' what-do-we-do text text-white manrope-bold '>
								What do we do?
							</p>
						</div>
						<div className='box-containers-what-we-do my-10'>
							<img
								src={WhatDoWeDo}
								alt=''
								srcset=''
								className='what-do-we-do-image'
							/>
							<button className='portfolio-btn ripple  homepage-button z-10 my-5 view-PortFolio-btn'
								onClick={() => {
									navigate("/portfolio")
								}}
							>
								View PortFolio
							</button>
						</div>
						<div className='fold-3-mobile'>
							<img src={ZigZag} alt='' style={{ margin: '48px' }} />
							<button className='ripple  homepage-button'
								onClick={() => {
									navigate("/portfolio")
								}}
							>
								View Portfolio
							</button>
						</div>
					</div>
				</div>
				<div className='fold-4 my-40 relative'>
					<img src={DotPattern} alt='' srcset='' className='absolute' />
					<div className='our-projects'>
						<div className='our-projects-container-1 '>
							<p className=' our-latest-pro text-white font-bold'>
								Our Latest Projects
							</p>
							<p className='text-white text-sm we-believe-in-letting'>
								We believe in letting our work speak to you.{' '}
							</p>
							<button className=' ripple homepage-button z-10'>
								View all Projects
							</button>
						</div>
						<CarouselNew />
					</div>
				</div>

				<div className='fold-5 flex text-white justify-center align-center my-20'>
					<div className='fold-5-container-1'>
						<p className='building-relationship font-bold'>
							Building relationships
						</p>
						<p className='alongwith'>
							Along with building exceptional user experiences for the brands we
							partner up with, we strive to build great interpersonal
							communication too.
						</p>
					</div>
					<div className='logo-container-homepage'>
						<img className='brandlogo' src={BrainChain} alt='' />
						<img className='brandlogo' src={TenTimes} alt='' />
						<img className='brandlogo' src={Central} alt='' />
						<img className='brandlogo' src={Eq8} alt='' />
						<img className='brandlogo' src={ItEx} alt='' />
						<img
							className='brandlogo pay10'
							src={Pay10}
							alt=''
							style={{ maxHeight: '35px', maxWidth: '141px' }}
						/>
						<img
							className='brandlogo'
							src={stuc}
							alt=''
							style={{ maxHeight: '45px', maxWidth: '141px' }}
						/>
						<img className='brandlogo' src={uptrado} alt='' />
					</div>
					<div className='logo-Mobile'>
						<img src={LogoMobile} alt='' className='logo-mobile-image' />
					</div>
				</div>

				<div className='fold-6 '>
					<div className='from-why-labs text-white'>
						<div className='from-why-1'>
							<p className='from-why-heading font-bold'>From WHY Labs</p>
							<div className='from-why-text-2'>
								Klose is the #1 way to share your digital visiting card with
								anyone, anywhere...
							</div>
							<div className='klose-box-container'>
								<div className='klose-sign-up' style={{ fontSize: '14px' }}>
									<p
										className='font-bold manrope-bold'
										style={{ fontSize: '20px' }}
									>
										500+
									</p>
									<p className='manrope'>Sign Ups</p>
								</div>
								<div className='klose-sign-up' style={{ fontSize: '12px' }}>
									<p
										className='font-bold manrope-bold'
										style={{ fontSize: '20px' }}
									>
										8000
									</p>
									<p className='manrope'> Cups of Coffee</p>
								</div>
								<div className='klose-sign-up' style={{ fontSize: '16px' }}>
									<p className='manrope'>
										<AllInclusiveIcon sx={{ fontSize: '30px' }} />
									</p>{' '}
									<p className='manrope'>Possibilities</p>
								</div>
							</div>
							<button
								className='explore-klose manrope-bold my-10'
								style={{ fontSize: '20px' }}
							>
								<a href="https://kloseapp.com/">
									Explore Klose{' '}
								</a>
							</button>
						</div>
						<div className='img-container-klose'>
							<img src={KloseDemo} className='klose-demo-image' alt='' />
							<button className='ripple  explore-klose-2 manrope-bold my-10'>
								<a href="https://kloseapp.com/">
									Explore Klose{' '}
								</a>
							</button>
						</div>
					</div>
				</div>

				<div className='lets-build-phone flex flex-col justify-center items-center text-white text-2xl text-center my-20 gap-10'>
					Let's build something great together
					<button className='homepage-button'
						onClick={() => {
							navigate("/contact")
						}}
					>Get In Touch</button>
				</div>
				<div className='fold-7 text-white'>
					<div className='manrope-bold lets-build'>
						{' '}
						Let's build something great together
					</div>
					<div className='get-in-touch-container '>
						<button className='ripple  get-in-touch homepage-button manrope'
						>
							Get in Touch
						</button>
					</div>

					<div className='form-container'>
						<img src={Flower} alt='' className='flower-image' />
						<div className='contact-form'>
							<form
								action='
						'
								onSubmit={handleSubmit}
							>
								<div className='input_container gap-5'>
									<div className='flex flex-col my-2 gap-2'>
										<label htmlFor=''>Full Name</label>
										<input type='text' placeholder='' value={name}
											onChange={(e) => {
												e.preventDefault()
												setName(e.target.value)
											}}
										/>

									</div>
									<div className='flex flex-col my-2 gap-2'>
										<label htmlFor=''>Email Id</label>
										<input type='text' placeholder='' value={email}
											onChange={(e) => {
												e.preventDefault()
												setEmail(e.target.value)

											}} />
									</div>
									<div className='flex flex-col my-2 gap-2'>
										<label htmlFor=''>Tell Us About Your Idea</label>
										<textarea type='text' placeholder='' className='form-text-area' value={idea} onChange={(e) => {
											e.preventDefault()
											setIdea(e.target.value)
										}}
											rows="8"
										/>

									</div>
									<button className='ripple form-button font-monorope'>
										Submit
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className='fold-8 text-white manrope'>
					<div className='footer-1 flex '>
						{/* <p
							onClick={() => {
								navigate("/")
							}}
						>Home</p> */}
						<p
							onClick={() => {
								navigate("/about-us")
							}}
						>About </p>
						<p
							onClick={() => {
								navigate("/portfolio")
							}}
						>Portfolio</p>
						<p
							onClick={() => {
								navigate("/contact")
							}}
						>Contact Us</p>
					</div>

					<div className='footer-2 '>
						<div className='social text-white'>
							<a href="https://www.linkedin.com/company/why-ventures">

								<LinkedIn sx={{ fontSize: '40px' }} />

							</a>
							{/* <Facebook sx={{ fontSize: '40px' }} /> */}
							{/* <Twitter sx={{ fontSize: '40px' }} /> */}
						</div>
						<div className='social-2 text-white'>
							<a href="https://www.linkedin.com/company/why-ventures">

								<LinkedIn sx={{ color: '#555555', fontSize: '40px' }} />

							</a>
							{/* <Facebook sx={{ color: '#555555', fontSize: '40px' }} /> */}
							{/* <Twitter sx={{ color: '#555555', fontSize: '40px' }} /> */}
						</div>
						<div className='inq'>
							For Queries <br />
							<a className='font-bold' href="mailto:admin@whyventures.org"> admin@whyventures.org</a>
						</div>
						<div className='based'>Our bunker is in Delhi, India</div>
					</div>
				</div>
			</div>
		</motion.div>
	)
}
