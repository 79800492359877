import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import TenTimesImage1 from './../../assets/TenTImes/Screenshot 2022-07-22 142347 1.svg'
import TenTimesImage2 from './../../assets/TenTImes/Screenshot 2022-07-22 142920 1.svg'
import WireFrame from './../../assets/TenTImes/Main Landing Page Copy 5 1.svg'
import MajorAt from './../../assets/TenTImes/Screenshot 2022-07-22 160523 1.svg'
import PinIcon from './../BitfireCaseStudy/../../assets/bitfire-case-study/PS_pin.svg'
import Arrow from "./../../assets/TenTImes/Vector 197.svg"
import UserPersona from "./../../assets/TenTImes/User Persona (1).svg"
import "./style.scss"
import OurProcess1 from './../../assets/TenTImes/Discover.svg'
import OurProcess2 from './../../assets/TenTImes/Define.svg'
import OurProcess3 from './../../assets/TenTImes/Ideate.svg'
import OurProcess4 from './../../assets/TenTImes/Design.svg'
import ProductUsers from "./../../assets/TenTImes/Product Users.svg"
import Feature1 from './../../assets/TenTImes/Group 1000004809.svg'
import Feature2 from './../../assets/TenTImes/Group 1000004808.svg'
import Feature3 from './../../assets/TenTImes/Group 1000004807.svg'
import Competitor1 from './../../assets/TenTImes/Ola Share (1).svg'
import Competitor2 from './../../assets/TenTImes/Bla Bla Car (1).svg'
import TaskMapping from './../../assets/TenTImes/ConceptMAp.svg'
import Visuals1 from './../../assets/TenTImes/Visuals landing page.svg'

const TenTimes = () => {
    const [loader, setLoader] = useState(true)


    useEffect(() => {
        setLoader(false)
    }, [loader])
    return (
        <>
            {
                loader ? (
                    <div>
                        loader
                    </div>
                )
                    : (
                        <div className='bitfire-case-study-container poppins-med'>
                            <Header />
                            <div className='bitfire-case-study-content-container container-sub'>
                                <div className='case-study-buttons-container flex px-32 py-4 gap-x-2'>
                                    <button className='case-study-button '>UI Case Study</button>
                                    <button className='case-study-button '>UX Case Study</button>
                                </div>
                                <div className='case-study-text-content'>
                                    <h1 className='heading-1 poppins-med text-primary-color px-32 py-4 text-4xl font-semibold'>
                                        UX & UI of Event Landing Page
                                    </h1>
                                    <p className='text-1 text-black-1 px-32 py-6 '>
                                        10times is a event management platform used by organizers & corporates globally to manage end to end lifecycle of business events. It can be used to market event, lead management, event content management for any types of trade shows, conferences, etc. worldwide.
                                    </p>
                                    <p className='text-1 text-black-1 px-32  '>Duration: 4 weeks</p>
                                    <p className='text-1 text-black-1 px-32  '>View Website:
                                        https://login.10times.com/eventmarketing </p>
                                </div>
                                <div className='image-hero flex justify-center py-6 object-contain aspect-w-16 aspect-h-9 mb-20'>
                                    <img src={TenTimesImage1} alt='' />
                                </div>
                                <div className='problem-statement-container px-48 py-16 mx-32 flex justify-center flex-col poppins text-primary-color rounded-lg relative mb-20'>
                                    <p className='problem-statement-heading text-4xl font-semibold py-2'>
                                        Problem Statement
                                    </p>
                                    <p className='problem-statement-text py-6'>
                                        {' '}
                                        The company’s landing page saw a huge drop in conversions. Also, their message about an event’s true potential wasn’t getting through.
                                    </p>
                                    <img src={PinIcon} alt='pin-icon' className='pin-icon absolute ' />
                                </div>

                                <div className='objective-container text-primary-color mx-32 mt-20 '>
                                    <p className='objective-heading text-4xl font-semibold'>
                                        Objectives & Goals
                                    </p>
                                    <ul className='flex py-6 gap-x-20'>
                                        <li>Increase the conversions on their landing page.</li>
                                        <li>
                                            Efficient way to communicate an event’s potential.
                                        </li>
                                    </ul>
                                </div>
                                <div className='process-container text-primary-color mx-32 mt-20 '>
                                    <p className='process-heading text-4xl font-semibold'>Our Process</p>
                                    <div className='process-image-container flex justify-around py-10 object-contain aspect-w-16 aspect-h-9 mb-20 items-start'>
                                        <img
                                            src={OurProcess1}
                                            alt='process'
                                            className='our-process-image m-10'
                                        />
                                        <img
                                            src={OurProcess2}
                                            alt='process'
                                            className='our-process-image m-10'
                                        />
                                        <img
                                            src={OurProcess3}
                                            alt='process'
                                            className='our-process-image m-10'
                                        />
                                        <img
                                            src={OurProcess4}
                                            alt='process'
                                            className='our-process-image m-10'
                                        />
                                        {/* <img src={BitfireCaseStudyImage1} alt='' /> */}
                                    </div>
                                </div>
                                <div className='business-container text-primary-color mx-32 mt-20 '>
                                    <p className='business-heading text-4xl font-semibold'>
                                        Business Challenges
                                    </p>
                                    <div>
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            Finding the organisers their target audience

                                        </p>{' '}
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            Clearly defining the value proposition of the event

                                        </p>{' '}
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            Promoting event to international audience keeping time and guidelines in-check.

                                        </p>{' '}
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />20%20%




                                            Allowing organizers to register their event straight-forwardly.

                                        </p>
                                    </div>
                                </div>{' '}

                                <div className='product-users-ten-times text-primary-color mx-32 mt-20 flex justify-center item-center'>
                                    <img src={ProductUsers} alt="" />
                                </div>

                                <div className='quantitative-container text-primary-color mx-32 mt-20 '>
                                    <p className='quantitative-heading text-4xl font-semibold mb-20'>
                                        Why is event marketing important?
                                    </p>
                                    <p className='observation-heading heading-main text-2xl font-semibold'>
                                        Observations
                                    </p>
                                    <p className='observation-heading heading-main text-s '>
                                        Source : LinkedIn, Vicme
                                    </p>
                                    <div className='flex justify-center align-center flex-column '>
                                        <div className='observation-content flex flex-col flex mb-10 '>
                                            <div className='observation-info flex justify-between mx-50 my-10'>
                                                <div className='m-10 content-center'>
                                                    <p className='text-5xl text-heading-yellow font-bold text-blue'>
                                                        60%
                                                    </p>
                                                    <p className='w-200 p-width'>
                                                        of leadership believe that events are the most critical marketing channel for achieving business goals.
                                                    </p>
                                                </div>
                                                <div className='m-10 content-center'>
                                                    <p className='text-5xl text-heading-yellow font-bold text-blue'>
                                                        55%
                                                    </p>
                                                    <p className='w-200 p-width'>
                                                        more attendees since the virtual events allows global audience to be a part of the events.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='observation-info  flex justify-between mx-50 my-10'>
                                                <div className='m-10 content-center '>
                                                    <p className='text-5xl text-heading-yellow font-bold text-blue'>
                                                        25%
                                                    </p>
                                                    <p className='w-200 p-width'>
                                                        more attendees since the virtual events allows global audience to be a part of the events.
                                                    </p>
                                                </div>
                                                <div className='m-10 content-center '>
                                                    <p className='text-5xl text-heading-yellow font-bold text-blue'>
                                                        92%
                                                    </p>
                                                    <p className='w-200 p-width'>
                                                        of marketers believe event softwares make it easier to achieve business outcomes (Bizzabo)

                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='userneeds-container container-sub text-primary-color mx-32 '>
                                    <p className='userneeds-heading heading-main text-4xl font-semibold'>
                                        User Needs
                                    </p>
                                    <div>
                                        <p className='flex user-need-text gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            User wants their event to get more traction and make it more discoverable.
                                        </p>{' '}
                                        <p className='flex user-need-text gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            User needs a time-efficient, one-stop solution to manage all mediums of marketing and promotion for their event
                                        </p>{' '}
                                        <p className='flex user-need-text gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            User needs a cost-effective and organised way to showcase their event to genuinely interested audience.
                                        </p>{' '}
                                    </div>
                                </div>{' '}
                                <div className='features-container container-sub text-primary-color mx-32 mt-20 '>
                                    <p className='userneeds-heading heading-main text-4xl font-semibold'>
                                        Features & Functionalities
                                    </p>
                                    <div className='feature-image-container flex justify-around py-10 object-contain aspect-w-16 aspect-h-9 items-start'>
                                        <img src={Feature1} alt='process' className='feature-image m-10' />
                                        <img src={Feature2} alt='process' className='feature-image m-10' />
                                        <img src={Feature3} alt='process' className='feature-image m-10' />
                                        {/* <img src={BitfireCaseStudyImage1} alt='' /> */}
                                    </div>
                                </div>{' '}
                                <div className='product-user-container container-sub text-primary-color  mt-10 mb-20 product-user-container text-primary-color mx-32 mt-20'>
                                    <p className='userneeds-heading heading-main text-4xl font-semibold'>
                                        Product User Challenges
                                    </p>
                                    <div>
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            Most event organizers wouldn’t be able to target the right audience to it fullest.
                                        </p>{' '}
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            There would be an overhead cost of managing different mediums of promotion making it expensive.
                                        </p>{' '}
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            Sign-up process on various platforms would be lenghthy, redundant and
                                            time consuming.
                                        </p>{' '}
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            The leads generated would not be managed to its best hence losing on valueable conversion.
                                        </p>{' '}
                                    </div>
                                </div>{' '}
                                <div className='competitor-container-1 container-sub text-primary-color mx-32 flex justify-between items-center'>
                                    <div>
                                        <p className='userneeds-heading heading-main text-4xl font-semibold'>
                                            Competitor Analysis
                                        </p>
                                        <div>
                                            <p className='competitor-sub-heading  heading-main text-2xl font-semibold mt-5'>
                                                Competitor 1
                                            </p>
                                            <p className='flex gap-x-20 mt-10 mb-20'>
                                                One engagement platform for all your events
                                                and audiences.
                                            </p>{' '}
                                        </div>
                                        <div>
                                            <p className='userneeds-heading heading-main text-2xl font-semibold mt-5'>
                                                Features:
                                            </p>
                                            <p className='flex gap-x-20 mt-10'>
                                                <img src={Arrow} alt='' />
                                                Venue sourcing, website building, employee wrangling, reporting and beyond.
                                            </p>{' '}
                                            <p className='flex gap-x-20 mt-10'>
                                                <img src={Arrow} alt='' />
                                                a centralized way to manage event promotion, registrations, attendee engagement, and event reporting.
                                            </p>{' '}
                                        </div>
                                    </div>
                                    <div className='red-img'>
                                        <img src={Competitor1} alt='' srcset='' />
                                    </div>
                                </div>{' '}
                            </div>
                            <div className='competitor-container-1 container-sub text-primary-color mx-32 flex justify-between items-center my-10'>
                                <div className='red-img'>
                                    <img src={Competitor2} alt='' srcset='' />
                                </div>
                                <div>
                                    <div>
                                        <p className='competitor-sub-heading  heading-main text-2xl font-semibold mt-5'>
                                            Competitor 2
                                        </p>
                                        <p className='flex gap-x-20 mt-10 mb-20'>
                                            Helping event planners create valuable in-person experiences, grow influential communities and expand brands.

                                        </p>{' '}
                                    </div>
                                    <div>
                                        <p className='userneeds-heading heading-main text-2xl font-semibold mt-5'>
                                            Features:
                                        </p>
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            Connecting exhibitors with attendees through video chat booths.
                                        </p>{' '}
                                        <p className='flex gap-x-20 mt-10'>
                                            <img src={Arrow} alt='' />
                                            Multitude of advertising and sponsorship options to increase revenue and impact.
                                        </p>{' '}
                                    </div>
                                </div>
                            </div>{' '}

                            <div className='container-sub product-user-container text-primary-color mx-32 mt-20'>
                                <p className='userneeds-heading heading-main text-4xl font-semibold'>
                                    Unique Features
                                </p>
                                <div>
                                    <p className='flex gap-x-20 mt-10'>
                                        <img src={Arrow} alt='' />
                                        Technology and insight-driven process of marketing automation.
                                    </p>{' '}
                                    <p className='flex gap-x-20 mt-10'>
                                        <img src={Arrow} alt='' />
                                        Free & personalized consultation on event marketing, listings, promotion, etc.
                                    </p>{' '}
                                    <p className='flex gap-x-20 mt-10'>
                                        <img src={Arrow} alt='' />
                                        Free & personalized consultation on event marketing, listings, promotion, etc.
                                    </p>{' '}
                                </div>
                            </div>{' '}




                            <div className='user-persona-container container-sub text-primary-color mx-32 mt-20 '>
                                <p className='objective-heading heading-main text-4xl font-semibold'>
                                    User Persona
                                </p>
                                <div className='flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 mb-1y0'>
                                    <img src={UserPersona} alt='process' />
                                </div>
                            </div>
                            <div className='design-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
                                <p className='design-heading heading-main text-4xl font-semibold'>
                                    Concept mapping
                                </p>
                                <div>

                                    <div className='flex design-text-p justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
                                        <img src={TaskMapping} alt='process' />
                                    </div>
                                </div>
                            </div>{' '}
                            <div className='product-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
                                <p className='userneeds-heading heading-main text-4xl font-semibold'>
                                    Concept building
                                </p>
                                <div>

                                    <p className='flex gap-x-20 mt-10'>

                                        The hero section illustrates an iceberg and the penguin exploring the depths. Just like an iceberg’s tip is visible and lot of it’s entire form is hidden, an event’s potential to reach people, drive growth and businesses is hidden. It’s success metrics are multi-faceted & depends on how well the event is set up, how quick is the registration, is the event promotion reaching the interested audience, are they able to get all the necessary details,
                                        what all modes of information are the audience using, for e.g.: social media, emails, etc and if they missed it in the first go, are they shown the promotions again in a non-annoying yet effective way?

                                    </p>{' '}
                                    <div className='flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
                                        <img src={TenTimesImage1} alt='process' />
                                    </div>
                                </div>

                                <p className='flex gap-x-20 mt-10'>


                                    This is where the entire concept of penguins and icebergs came in which led to the color selection of whites and blues and a highlight of orange as a complemetary tone. We wanted the real life feel of booking a demo as buying the ticket to a show, hence incorporating the look and feel of a ticket stub.
                                </p>
                                <div>
                                    <div className='flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
                                        <img src={TenTimesImage2} alt='process' />
                                    </div>
                                </div>
                            </div>{' '}
                            <div className='architecture-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
                                <p className='architecture-heading heading-main text-4xl font-semibold'>
                                    Major attributes
                                </p>

                                <p className='flex gap-x-20 mt-10'>

                                    Multi-channel approach allows organizers to achieve more visibility, drive attendance and integrate digital touchpoints. Such strategy improves the customer experience and provides more channels for engagement–whether it is on mobile or web.</p>
                                <div className='architecture-image-container'>
                                    <div className=' flex justify-center py-10 object-contain aspect-w-16 aspect-h-9 '>
                                        <img
                                            className='architecture-image'
                                            src={MajorAt}
                                            alt='process'
                                        />
                                    </div>
                                </div>
                            </div>{' '}
                            <div className='architecture-user-container container-sub text-primary-color mx-32 mt-10 mb-20'>
                                <p className='userneeds-heading heading-main text-4xl font-semibold mb-10'>
                                    WireFrame
                                </p>
                                <div className='wireframe-images flex  justify-center gap-x-10 py-10 mt-20'>
                                    {' '}
                                    <img src={WireFrame} alt="" srcset="" />



                                </div>
                            </div>{' '}
                            <div className='visual-container container-sub flex flex-col text-primary-color mx-32 mt-20 mb-20 '>
                                <p className='visual-heading heading-main text-4xl font-semibold mb-20'>
                                    Visuals
                                </p>
                                <div className=' container-sub  flex flex-col justify-around mb-10'>

                                    <div className='visual-content-sub'>
                                        <img src={Visuals1} alt='process' />
                                    </div>
                                </div>
                            </div>
                            <div className='learning-container container-sub p-10'>
                                <div className='learning-container container-sub text-primary-color mx-32 mt-20 '>
                                    <p className='objective-heading heading-main text-4xl font-semibold'>
                                        My Learnings:
                                    </p>
                                    <div className='flex justify-center py-10 mb-20'>
                                        <p className='flex gap-x-20 mt-10 justify-center text-primary'>
                                            This project was one-of-its kind in the sense, understanding the real-life persona, attend the actual client calls of the potential or exisiting uptrado platform users to understand the buying & selling process, validation of visuals concepts after every iteration from real suppliers and sellers by demonstrating an actual demo, taking a consensus on features and evolving the design accordingly.
                                        </p>{' '}
                                    </div>
                                </div>
                            </div>
                            <div className='py-10 thanks mt-20 flex flex-col justify-center align-center'>
                                <p className='objective-heading heading-main text-4xl font-semibold'>
                                    Thanks for reading
                                </p>
                                <p className=' px-10 mt-10 text-primary'>
                                    Do you have any project you would like us to bring to life? Send us a
                                    mail on &nbsp
                                    <a className='font-bold' href="mailto:admin@whyventures.org"> admin@whyventures.org</a>
                                </p>{' '}
                            </div>
                        </div>
                    )
            }

        </>


    )
}
export default TenTimes
