import React, { useState } from 'react'
import Header from '../../components/HeaderWhite'
import "./../AboutUs/about.scss"
import { isMobile } from 'react-device-detect';
import { config } from '../../config';
import axios from "axios"


export const Contact = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [idea, setIdea] = useState("")


    //handle submit
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false
        ) {
            alert('Please enter a valid email address.')
            return
        }
        else if (email === "") {
            alert("Email cannot be empty!")
            return

        }

        else if (idea === "") {
            alert("Tell us about your Idea? ")
        }

        const Header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        await axios.post(`${config.formApi}`, { name: name, text: idea, email: email }, Header)
            .then(res => {
                const { success } = res.data
                if (success === false) {
                    return alert(res.data.message)
                } else {
                    alert('Form submited. ')
                }
            })
    }
    return (
        <>
            {
                isMobile ? (
                    <div className='portfolio-container-main about-container-main contact-cont poppins-med bg-black mb-20 '


                    >

                        <Header />
                        <div className='' style={{ margin: "-8px" }}>
                            <div className='text-white manrope text-4xl mt-20 mx-10'>
                                Hey there!
                            </div>
                            <div className='mx-10 flex flex-col gap-4 mt-10'>
                                <div className="text-white manrope text-sm">
                                    Feel free to contact us with any questions.
                                </div>
                                <div className="text-white manrope text-sm ">
                                    Email: <br />
                                    <div className='manrope-bold'>
                                        hello@whyventures.com
                                    </div>
                                </div>
                                <div className="text-white manrope text-sm ">
                                    Phone: <br />
                                    <div className='manrope-bold'>
                                        9540976773
                                    </div>
                                </div>

                            </div>

                            <form action="" className='form-cont flex flex-col justify center text-white m-10 manrope mb-20 gap-5'
                                onSubmit={handleSubmit}
                            >
                                <div className='flex flex-col'>
                                    <label htmlFor="">Name</label>
                                    <input className="input-form-contact" type="text" value={name} onChange={(e) => {
                                        e.preventDefault()
                                        setName(e.target.value)
                                    }} />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="">Email</label>
                                    <input className="input-form-contact" type="text"
                                        value={email}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            setEmail(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="">Tell us  about your idea </label>
                                    <textarea type='text' placeholder='' className='form-text-area' value={idea} onChange={(e) => {
                                        e.preventDefault()
                                        setIdea(e.target.value)
                                    }}
                                        rows="8"
                                    />
                                </div>
                                <button className='homepage-button mt-5 mb-20' style={{ maxWidth: "120px" }}> Submit </button>
                            </form>
                        </div>
                    </div >
                ) : (
                    <h1>
                        This page is not compartible on this device.
                    </h1>
                )


            }

        </>

    )

}
