import React from 'react'
import WhyLogo from './../assets/header/Why-logo.svg'
import { MuiDrawer } from './Drawer'
import './style/header.scss'
import { ScrollIndicator } from './ScrollIndicator'
import { useNavigate } from 'react-router-dom'


const Header = () => {
	const completion = ScrollIndicator()
	const navigate = useNavigate()
	const ContactHandler = () => {
		window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
	}


	return (
		<nav className='header relative flex justify-between px-24 py-8 text-xm poppins-med flex-wrap'>
			<img src={WhyLogo} alt='why-logo'
				onClick={() => {
					navigate("/")
				}}
			/>
			<div className='nav-items flex text-black-1 gap-x-8 '>
				<p
					onClick={() => {
						navigate("/")
					}}
				> Home</p>
				<p
					onClick={() => {
						navigate("/portfolio")
					}}
				>
					Portfolio</p>
				<p
					onClick={() => {
						navigate("/about-us")
					}}
				> About Us</p>
				<p onClick={ContactHandler}> Contact</p>
			</div>
			<div className='hamburger'>
				<MuiDrawer />
			</div>

			<span
				className='scroll-indicator fixed h-1 w-full top-0'
				style={{
					transform: `translateX(${completion - 100}%)`,
					transition: '0.5s',
				}}
			></span>
		</nav>
	)
}

export default Header
