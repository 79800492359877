import React, { useRef } from 'react'
import WhyLogo from './../assets/header/white-1.svg'
import { MuiDrawer } from './Drawer'
import { MuiDrawerWhite } from './DrawerWhite'
// import './header.scss'
import { ScrollIndicator } from './ScrollIndicator'
import { useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const HeaderWhite = () => {
	const completion = ScrollIndicator()
	const scollToRef = useRef();
	const navigate = useNavigate()
	const ContactHandler = () => {

		window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
	}
	return (
		<nav className='header relative flex justify-between px-24 py-8 text-xm poppins-med flex-wrap'>
			<img src={WhyLogo} alt='why-logo' onClick={() => navigate('/')} />
			<div className='header-white nav-items flex text-white gap-x-8 '>
				<p onClick={() => navigate('/')}> Home</p>
				<p onClick={() => navigate('/portfolio')}> Portfolio</p>
				<p onClick={() => navigate('/about-us')} > About Us</p>
				<p onClick={ContactHandler}> Contact</p>
			</div>
			<div className='hamburger text-white'>
				<MuiDrawerWhite />
			</div>
			<span
				className='scroll-indicator-2 fixed h-1 w-full top-0'
				style={{
					transform: `translateX(${completion - 100}%)`,
					transition: '0.5s',
					zIndex: "111111111111"

				}}
			></span>
		</nav>
	)
}

export default HeaderWhite
