import { SwipeableDrawer, Box, Typography, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import './style/header.scss'
import { useNavigate } from 'react-router-dom'

export const MuiDrawerWhite = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const navigate = useNavigate()
	return (
		<div className='hamburger-menu'>
			<IconButton onClick={() => setIsDrawerOpen(true)}>
				{isDrawerOpen ? (
					<CloseIcon style={{ color: 'white' }} />
				) : (
					<MenuIcon style={{ color: 'white' }} />
				)}
			</IconButton>

			<SwipeableDrawer
				anchor='left'
				open={isDrawerOpen}
				onClose={() => setIsDrawerOpen(false)}
			>
				<Box p={2} width='300px' textAlign='center' role='presentation'>
					<p className='ham-item'
						onClick={() => {
							navigate("/")
						}}
					>Home</p>
					<p className='ham-item'
						onClick={() => {
							navigate("/portfolio")
						}}
					>Portfolio</p>
					<p className='ham-item'
						onClick={() => {
							navigate("/about-us")
						}}
					>About Us</p>
					<p className='ham-item'
						onClick={() => {
							navigate("/contact")
						}}
					>Contact</p>
				</Box>
			</SwipeableDrawer>
		</div>
	)
}