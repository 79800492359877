import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { HomePage } from './pages/HomePage/HomePage';
import BitfireCaseStudy from './pages/BitfireCaseStudy/BitfireCaseStudy';
import UptradoCaseStudy from "./pages/UptradoCaseStudy/UptradoCaseStudy";
import TenTimes from "./pages/TenTimes/TenTimes";
import { PortFolio } from "./pages/PortFolio/PortFolio";
import { AboutUs } from "./pages/AboutUs/AboutUs";
import { Contact } from "./pages/Contact/Contact";
import { AnimatePresence } from "framer-motion"
import React, { useEffect, useState } from 'react'
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";

function App() {
  const [scrollValue, setScrollValue] = useState(0);
  useEffect(() => {

    const onScroll = (e) => {
      setScrollValue(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollValue]);
  return (

    <AnimatePresence>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/bitfire-case-study" element={<BitfireCaseStudy />} />
          <Route path="/uptrado" element={<UptradoCaseStudy />} />
          <Route path="/tentimes" element={<TenTimes />} />
          <Route path="/portfolio" element={<PortFolio />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />

        </Routes>
      </BrowserRouter>
    </AnimatePresence>
  );
}

export default App;


