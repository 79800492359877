import React, { useEffect, useState } from 'react'
// import { Facebook, LinkedIn, Twitter } from '@mui/icons-material'
import { LinkedIn } from '@mui/icons-material'
import Header from '../../components/HeaderWhite'
import FlowerFooter from './../../assets/Why Homepage images/flowerfooter.svg'
import { useNavigate } from 'react-router-dom'
import Strategy from "./../../assets/about-us/strategy.svg"
import Brand from "./../../assets/about-us/Brand IDentity.svg"
import App from "./../../assets/about-us/Applications.svg"
import Dev from "./../../assets/about-us/Development.svg"
import wdd from "./../../assets/about-us/wdd.svg"
import Image1 from "./../../assets/about-us/yasim.png"
import Image2 from "./../../assets/about-us/ahsan.png"
import Image3 from "./../../assets/about-us/xyz.png"
import "./about.scss"
import { motion } from 'framer-motion'
import axios from "axios"
import { config } from '../../config'




export const AboutUs = () => {
    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [idea, setIdea] = useState("")


    //handle submit
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false
        ) {
            alert('Please enter a valid email address.')
            return
        }
        else if (email === "") {
            alert("Email cannot be empty!")
            return

        }

        else if (idea === "") {
            alert("Tell us about your Idea? ")
        }

        const Header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        await axios.post(`${config.formApi}`, { name: name, text: idea, email: email }, Header)
            .then(res => {
                const { success } = res.data
                if (success === false) {
                    return alert(res.data.message)
                } else {
                    alert('Form submited. ')
                }
            })
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <motion.div className='portfolio-container-main about-container-main poppins-med bg-black'
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Header />


            <div className='About-us homepage-hero-text text-white manrope-bold mb-10 text-4xl text-center mt-20'>
                Building products the right way.
            </div>
            <div className='fun-space-container flex flex-col mx-4 gap-4 text-sm px-20'>

                <div className=' fun-space flex flex-col text-white text-center'>
                    WHY is a fun space to create unique and balanced
                    digital experiences...
                </div>
                <div className='our-work flex flex-col text-white text-center'>
                    Our work encompasses
                </div>
                <div className='flex justify-center my-20 gap-5 flex-wrap about-us-icons'>
                    <img src={Strategy} alt="" />
                    <img src={Brand} alt="" />
                    <img src={App} alt="" />
                    <img src={Dev} alt="" />
                    <img src={wdd} alt="" />
                </div>
                {/* <div className='our-team-about-us flex flex-col text-white  px-20 text-center' >
                    Our team members are passionate designers and developers,and whether working collaboratively or independently, we do so with a smile on our faces!
                </div> */}
            </div>

            {/* <div className='c-text-m text-white mx-20 flex items-end gap-4 justify-center my-20'>
                <div className='text-lg flex justify-items-end'>A</div>
                <div className='text-8xl'>TINY</div>
                <div className='text-lg' >team making</div>
                <br />
                <div className='text-lg'>a</div>
                <div className='text-6xl'>big</div>
                <div>impact.</div>
            </div> */}

            {/* <div className='flex flex-col text-white  px-20 items-center' >
                <div className='flex justify-center my-20 gap-5 flex-wrap'>
                    <img src={Image1} className="image-about-us" alt="" />
                    <img src={Image2} className="image-about-us" alt="" />
                    <img src={Image3} className="image-about-us" alt="" />
                </div>
                <div className="l-k-brilliant text-center">
                    A brilliant work culture, amazing perks & super talented people drive WHY.
                    After all, no one said building exciting digital platforms can’t be fun!</div>
                <button className='homepage-button my-10'>See Openings</button>
            </div> */}



            <div className='footer-portfolio'>
                <div className='fold-7 text-white'>
                    <div className='manrope-bold lets-build'>
                        {' '}
                        Let's build something great together
                    </div>
                    <div className='get-in-touch-container '>
                        <button className='ripple  get-in-touch homepage-button manrope'>
                            Get in Touch
                        </button>
                    </div>

                    <div className='form-container'>
                        <img src={FlowerFooter} alt='' className='flower-image' />
                        <div className='contact-form'>
                            <form
                                action='
						'
                                onSubmit={handleSubmit}
                            >
                                <div className='input_container gap-5'>
                                    <div className='flex flex-col my-2 gap-2'>
                                        <label htmlFor=''>Full Name</label>
                                        <input type='text' placeholder='' value={name} onChange={(e) => {
                                            e.preventDefault()
                                            setName(e.target.value)
                                        }} />

                                    </div>
                                    <div className='flex flex-col my-2 gap-2'>
                                        <label htmlFor=''>Email Id</label>
                                        <input type='text' placeholder='' value={email} onChange={(e) => {
                                            e.preventDefault()
                                            setEmail(e.target.value)
                                        }} />
                                    </div>
                                    <div className='flex flex-col my-2 gap-2'>
                                        <label htmlFor=''>Tell Us About Your Idea</label>
                                        <textarea type='text' placeholder='' className='form-text-area' value={idea} onChange={(e) => {
                                            e.preventDefault()
                                            setIdea(e.target.value)
                                        }}
                                            rows="8"
                                        />

                                    </div>
                                    <button className='ripple form-button font-monorope'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='lets-build-phone flex flex-col justify-center items-center text-white text-2xl text-center my-20 gap-10'>
                    Let's build something great together
                    <button className='homepage-button'>Get In Touch</button>
                </div>
                <div className='fold-8 text-white manrope'>
                    <div className='footer-1 flex '>
                        <p
                            onClick={() => {
                                navigate("/")
                            }}
                        >Home</p>
                        <p
                            onClick={() => {
                                navigate("/about-us")
                            }}
                        >About </p>
                        <p
                            onClick={() => {
                                navigate("/portfolio")
                            }}
                        >Portfolio</p>
                        <p
                            onClick={() => {
                                navigate("/contact")
                            }}
                        >Contact Us</p>
                    </div>

                    <div className='footer-2 '>
                        <div className='social text-white'>
                            <a href="https://www.linkedin.com/company/why-ventures">

                                <LinkedIn sx={{ fontSize: '40px' }} />

                            </a>
                            {/* <Facebook sx={{ fontSize: '40px' }} />
                            <Twitter sx={{ fontSize: '40px' }} /> */}
                        </div>
                        <div className='social-2 text-white'>
                            <a href="https://www.linkedin.com/company/why-ventures">

                                <LinkedIn sx={{ color: '#555555', fontSize: '40px' }} />

                            </a>
                            {/* <Facebook sx={{ color: '#555555', fontSize: '40px' }} />
                            <Twitter sx={{ color: '#555555', fontSize: '40px' }} /> */}
                        </div>
                        <div className='inq'>
                            For Queries <br />
                            <a href="mailto:admin@whyventures.org"> admin@whyventures.org</a>
                        </div>
                        <div className='based'>Our bunker is in Delhi, India</div>
                    </div>
                </div>
            </div>



        </motion.div>
    )

}
